import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/projectsTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Opsdroid was the first open-source project that I've contributed to. I've been contributing since September 2017 and later on became a Maintainer for the project. Since I've been contributing to Opsdroid for such a long time, it will be hard to show all that I've done. If you are curious you can see `}<a parentName="p" {...{
        "href": "https://github.com/opsdroid/opsdroid/commits?author=FabioRosado"
      }}>{`my list of commits`}</a>{`.`}</p>
    <p>{`As part of the Opsdroid team, I also have to do tasks such as triaging issues, code review PRs, help, guide and mentor new contributors, merge PR's, implement new features and also work with the team to decide plans for the project.`}</p>
    <p>{`Let's have a look at a few things that I added to the project.`}</p>
    <h2 {...{
      "id": "connectors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connectors",
        "aria-label": "connectors permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connectors`}</h2>
    <p>{`Connectors are used to receive and send messages from chat services.`}</p>
    <ul>
      <li parentName="ul">{`Added the Telegram connector`}</li>
      <li parentName="ul">{`Added the Rocket.chat connector`}</li>
      <li parentName="ul">{`Added the Twitch connector`}</li>
    </ul>
    <h2 {...{
      "id": "parsers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#parsers",
        "aria-label": "parsers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Parsers`}</h2>
    <p>{`Parses are Natural Language Understanding services that give opsdroid more context.`}</p>
    <ul>
      <li parentName="ul">{`Added the Wit.ai parser`}</li>
      <li parentName="ul">{`Added Recast.ai parser (Now known as SAP Conversational AI)`}</li>
      <li parentName="ul">{`Added IBM Watson parser`}</li>
      <li parentName="ul">{`Updated Dialogflow parser to V2`}</li>
    </ul>
    <h2 {...{
      "id": "logging",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#logging",
        "aria-label": "logging permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Logging`}</h2>
    <ul>
      <li parentName="ul">{`Done various refactors done to the logging messages`}</li>
      <li parentName="ul">{`Moved logs to appdir location - keeps consistent through all platforms`}</li>
      <li parentName="ul">{`Added filters to logging`}</li>
      <li parentName="ul">{`Added logs rotation`}</li>
    </ul>
    <h2 {...{
      "id": "configuration-and-cli",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuration-and-cli",
        "aria-label": "configuration and cli permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuration and CLI`}</h2>
    <ul>
      <li parentName="ul">{`Added configuration validation`}</li>
      <li parentName="ul">{`Added feature to run config validation without having to start the bot`}</li>
      <li parentName="ul">{`Added a flag to CLI to load a config file from a different path`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`list-modules`}</inlineCode>{` command to CLI to show a list of active modules`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`build`}</inlineCode>{` command to CLI to load and load dependencies without running the bot`}</li>
      <li parentName="ul">{`Changed config layout - use dicts of dicts instead of list of dicts`}</li>
    </ul>
    <h2 {...{
      "id": "loader",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#loader",
        "aria-label": "loader permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Loader`}</h2>
    <ul>
      <li parentName="ul">{`Updated loader to prevent the execution of arbitrary code by exploiting a vulnerability in PyYaml load method`}</li>
      <li parentName="ul">{`Refactored some parts of the loader to improve performance`}</li>
    </ul>
    <h2 {...{
      "id": "documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#documentation",
        "aria-label": "documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Documentation`}</h2>
    <ul>
      <li parentName="ul">{`Created tutorials to help beginners get started with opsdroid`}</li>
      <li parentName="ul">{`Added VIM tutorial`}</li>
      <li parentName="ul">{`Added examples`}</li>
      <li parentName="ul">{`Updated contributing.md`}</li>
      <li parentName="ul">{`Updated README.md`}</li>
      <li parentName="ul">{`Added documentation for new features`}</li>
      <li parentName="ul">{`Added documentation about using parsers with a different language`}</li>
    </ul>
    <h2 {...{
      "id": "website",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#website",
        "aria-label": "website permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Website`}</h2>
    <ul>
      <li parentName="ul">{`Designed a new page for the project`}</li>
      <li parentName="ul">{`Coded new page for the project`}</li>
      <li parentName="ul">{`Updated module list on the website`}</li>
    </ul>
    <h1 {...{
      "id": "info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#info",
        "aria-label": "info permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Info`}</h1>
    <p><strong parentName="p">{`Official site:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://opsdroid.github.io"
      }}>{`https://opsdroid.github.io`}</a></p>
    <p><strong parentName="p">{`GitHub repo:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://github.com/opsdroid/opsdroid"
      }}>{`https://github.com/opsdroid/opsdroi`}</a></p>
    <p><strong parentName="p">{`Creator:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://www.jacobtomlinson.co.uk"
      }}>{`Jacob Tomlinson`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      